@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html::-webkit-scrollbar {
    display: none;
  }

  html {
    background: #fff;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

@layer components {
  .active {
    @apply bg-white text-grey-800 !important;
  }

  /* Custom Scroll */
  .custom-scroll::-webkit-scrollbar {
    @apply bg-white w-3 h-3;
  }
  .custom-scroll::-webkit-scrollbar-track {
    @apply bg-white;
  }
  .custom-scroll::-webkit-scrollbar-thumb {
    @apply bg-[#babac0] rounded-full border-2 border-solid border-white;
  }
  .custom-scroll::-webkit-scrollbar-button {
    @apply hidden;
  }

  .hidden-scroll::-webkit-scrollbar {
    @apply hidden;
  }
}
